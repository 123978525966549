<template>
  <NuxtLink
    v-if="isStartpage ? banner.showOnStartpage : true"
    :id="sluggify(banner.name)"
    :to="bannerPath(banner)"
    :target="banner.external ? '_blank' : ''"
    class="safari-mask-transition group relative overflow-hidden rounded-lg bg-black/6"
    :class="[
      {
        'hover:cursor-pointer': !banner.disabled,
        'xs:col-span-full md:col-span-1 lg:col-span-2': primary,
        primary: primary,
      },
      primary ? primaryAspectRatio : 'aspect-square',
    ]"
  >
    <OptimizedImage
      :src="banner.imageUrl"
      :alt="banner.name"
      class="w-full rounded-lg"
      :class="{
        'transition-transform duration-150 group-hover:scale-110':
          !banner.disabled && hoverZoom,
      }"
      :lazy="!primary"
      :sizes="
        primary
          ? 'xs:100vw sm:100vw md:100vw lg:544px'
          : 'xs:100vw sm:50vw md:50vw lg:50vw xl:264px'
      "
      :modifiers="{ resource: 'banner' }"
      :preload="primary"
    />
    <div
      v-if="banner.disabled"
      class="absolute bottom-0 left-0 z-10 size-full bg-supporting-light-2 opacity-70"
    ></div>
  </NuxtLink>
</template>

<script setup lang="ts">
import { sluggify } from '#root/shared/utils/types/string-utils';

const localePath = useLocalePath();

const shopName = computed(
  () => useShopCountryConfig().routes[useLanguage()].shop
);

// Define props
const props = defineProps({
  banner: {
    type: Object,
    default: () => {},
  },
  isStartpage: {
    type: Boolean,
    default: false,
  },
  primary: {
    type: Boolean,
    default: false,
  },
  primaryAspectRatio: {
    type: String,
    default: 'aspect-[544/316]',
  },
  hoverZoom: {
    type: Boolean,
    default: true,
  },
});

const hasExternalPath = (bannerItem: any) => {
  const { path } = bannerItem;
  return (
    path.startsWith('http') || path.startsWith('https') || path.startsWith('//')
  );
};

const bannerPath = (bannerItem: any) => {
  const { banner } = props;
  if (
    (banner.campaignFilter || banner.couponCode) &&
    !hasExternalPath(bannerItem)
  ) {
    return localePath({
      name: 'shop-campaigns-slug',
      params: { slug: bannerItem.slug },
    });
  }
  if (hasExternalPath(bannerItem)) {
    return bannerItem.path;
  }
  if (typeof bannerItem.path === 'string') {
    return localePath(`/${shopName.value}${bannerItem.path}`);
  }
  return localePath(bannerItem.path);
};
</script>
